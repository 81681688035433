import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import TermView from '../views/TermView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:chain',
    name: 'home',
    component: HomeView
  },
  {
    path: '/pages/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/:chain/terminos-y-condiciones',
    name: 'terms',
    component: TermView
  }
]

const router = new VueRouter({
  routes
})

export default router