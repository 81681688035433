<template>
    <v-container fluid class="vh-100">
        <v-col v-if="retryCount < 3" cols="12" class="pl-0 pr-0 pt-0">
            <div v-if="termsText">
                <p v-html="termsText"></p>
            </div>
        </v-col>
    </v-container>
</template>
  
<script>
    import axios from 'axios';
    const axiosRetry = require('axios-retry');
    axiosRetry(axios, { retries: process.env.VUE_APP_API_TRY });

    export default {
        name: 'TermView.vue',
        data() {
            return {
                termsText: "",
                retryCount: 0,
            }
        },
        methods: {
            getChain() {
                if (this.retryCount >= 3) {
                    console.log('Se ha alcanzado el límite de reintentos');
                    return;
                }
                axios({
                    method: "get",
                    url: process.env.VUE_APP_API_URL + '/api/cadena/prefijo/' + this.$route.params.chain,
                })
                .then((response) => {
                    if (response.data.data.politicas_de_datos && response.data.data.politicas_de_datos.length > 0) {
                        this.termsText = response.data.data.politicas_de_datos[0].acuerdo_politica;
                    } else {
                        console.warn("No se encontraron políticas de datos.");
                    }
                })
                .catch(err => {
                    console.log('Error de conexión: ' + err.message);
                    this.retryCount++;

                    if (this.retryCount < 3) {
                        setTimeout(() => this.getChain(), 2000);
                    }
                });
            },
        },
        created() {
            this.getChain();
        },
    }
</script>